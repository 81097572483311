import {CustomerDetailsOpen} from './Components/CustomerDetailsOpen';
import {CustomerDetailsCollapsed} from './Components/CustomerDetailsCollapsed';
import React from 'react';
import {Step} from '../../StepsManager/Components/Step';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {StepImplementationProps, StepState} from '../../../../../types/app.types';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {withCustomerDetailsData} from './WithCustomerDetailsData/WithCustomerDetailsData';
import {withMemberDetailsData} from '../../../MemberDetails/WithMemberDetailsData';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../constants';
import {classes as commonClasses} from '../../../common.st.css';

export enum CustomerDetailsStepDataHook {
  root = 'CustomerDetailsStep.root',
  collapsed = 'CustomerDetailsStep.collapsed',
  open = 'CustomerDetailsStep.open',
  empty = 'CustomerDetailsStep.empty',
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export const CustomerDetailsStep = ({index}: StepImplementationProps) => {
  const {experiments} = useExperiments();
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {isShippingFlow},
    memberStore: {isMember},
    stepsManagerStore: {stepsList},
    navigationStore: {isDonationsTheme},
  } = useControllerProps();
  const stepState = stepsList[0].state;

  const getTitle = (): string => {
    let title: string = '';
    if (!isMember && experiments.enabled(SPECS.AddTitleToDeliveryDetails)) {
      if (isDonationsTheme) {
        title = localeKeys.checkout.donations.donor_details();
      } else {
        title =
          stepState === StepState.COLLAPSED && isShippingFlow
            ? localeKeys.checkout.customer_delivery_details_title()
            : localeKeys.checkout.customer_details();
      }
    } else if (isShippingFlow) {
      title = localeKeys.checkout.delivery_details_title();
    } else {
      title = isDonationsTheme ? localeKeys.checkout.donations.donor_details() : localeKeys.checkout.customer_details();
    }
    return title;
  };

  return (
    <Step
      index={index!}
      title={getTitle()}
      dataHook={CustomerDetailsStepDataHook.root}
      openComponent={WrappedCustomerDetailsOpenStep}
      collapsedComponent={CustomerDetailsCollapsed}
      classNameDynamicStep={
        experiments.enabled(SPECS.SupportNewMobileSummaryBehavior) ? commonClasses.oneColumnViewPadding : undefined
      }
    />
  );
};

const WrappedCustomerDetailsOpenStep = withMemberDetailsData(
  withCustomerDetailsData(CustomerDetailsOpen),
  (checkout) => checkout.shippingDestination,
  'validateShippingAddress'
);
